import React, { useState, useEffect } from 'react'
import { Card, CardBody} from 'reactstrap'

import SuggestionsTable from './partials/SuggestionsTable'
import NewSuggestionModal from './partials/NewSuggestionModal'
import ListTopBar from '../../components/ListTopBar'

import { getSuggestions } from './services'
import { handleError } from '../../helpers'

export default function SuggestionsList() {
	const [suggestions, setSuggestions] = useState(null)
	const [filterType, setFilterType] = useState('message')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!suggestions && getSuggestions({
			[`filter[${filterType}]`]: filterValue,
			include: '',
		})
			.then(res => setSuggestions(res.data.data))
			.catch(error => handleError(error))
	}, [ suggestions, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setSuggestions(null)}
				title="Listado de Mensajes de Seguimiento"
				subtitle="Mensajes sugeridos a los asesores en el Cotizador Web Spring"
				options={[
					{ label: 'Buscar por mensaje', value: 'message' },
				]}
				ctaText="Crear Mensaje Sugerido"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<SuggestionsTable 
						suggestions={suggestions} 
						reload={()=>setSuggestions(null)}
					/>
				</CardBody>
			</Card>
            { showNewModal && (
                <NewSuggestionModal 
                    visible
                    onClose={() => setShowNewModal(false)}
                    reload={() => setSuggestions(null)}
                />
            )}
		</React.Fragment>
	)
}