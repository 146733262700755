import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Users from "./containers/Users"
import Stores from "./containers/Stores"
import MetricLists from "./containers/MetricLists"
import MetricResponses from "./containers/MetricResponses"
import Promos from "./containers/Promos"
import Surveys from "./containers/Surveys"
import SurveyResponses from "./containers/SurveyResponses"
import TicketCategories from "./containers/TicketCategories"
import Tickets from "./containers/Tickets"
import TaskLists from "./containers/TaskLists"
import TaskResponses from "./containers/TaskResponses"
import Checkins from "./containers/Checkins"
import Visits from "./containers/Visits"
import Dashboards from "./containers/Dashboards"
import Notifications from "./containers/Notifications"
import Competitors from "./containers/Competitors"
import CompetitorRates from "./containers/CompetitorRates"
import CompetitorReferences from "./containers/CompetitorReferences"
import CustomRates from "./containers/CustomRates"
import Reports from "./containers/Reports"
import Products from "./containers/Products"
import Suggestions from "./containers/Suggestions"
import SmsCampaigns from "./containers/SmsCampaigns"

import MainLayout from "./layouts/MainLayout"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/dashboards/visits" />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>					
					<Route path="/dashboards" component={Dashboards} />
					<Route path="/users" component={Users} />
					<Route path="/stores" component={Stores} />

					<Route path="/metric_lists" component={MetricLists} />
					<Route path="/metric_responses" component={MetricResponses} />

					<Route path="/promos" component={Promos} />
					
					<Route path="/surveys" component={Surveys} />
					<Route path="/survey_responses" component={SurveyResponses} />
					
					<Route path="/tickets" component={Tickets} />
					<Route path="/ticket_categories" component={TicketCategories} />
					
					<Route path="/task_lists" component={TaskLists} />
					<Route path="/task_responses" component={TaskResponses} />
					
					<Route path="/visits" component={Visits} />
					<Route path="/checkins" component={Checkins} />

					<Route path="/competitors" component={Competitors} />
					<Route path="/competitor_rates" component={CompetitorRates} />
					<Route path="/competitor_references" component={CompetitorReferences} />
					<Route path="/custom_rates" component={CustomRates} />
					
					<Route path="/products" component={Products} />
					<Route path="/suggestions" component={Suggestions} />
					<Route path="/sms_campaigns" component={SmsCampaigns} />

					<Route path="/notifications" component={Notifications} />
					<Route path="/reports" component={Reports} />
					
					<Route exact path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}