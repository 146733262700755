import springApi from '../../services/springApi'

export const getSuggestions = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/suggestions', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSuggestion = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/suggestions', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSuggestion = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/suggestions/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSuggestion = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/suggestions/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)