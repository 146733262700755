import React, { useState, useEffect } from 'react'
import { Card, CardBody} from 'reactstrap'

import PromosTable from './partials/PromosTable'
import NewPromoModal from './partials/NewPromoModal'
import ListTopBar from '../../components/ListTopBar'

import { getPromos } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function PromosList() {
	const [promos, setPromos] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!promos && getPromos({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'promoDetails,comments',
		})
			.then(res => {
				setPromos(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ promos, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setPromos(null)}
				title="Listado de Dinámicas"
				subtitle="Listados de activaciones registradas"
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por marca', value: 'brand' },
					{ label: 'Buscar por tienda asociada', value: 'modelStores.store.name' },
				]}
				ctaText="Crear Dinámica"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<PromosTable 
						promos={promos} 
						reload={()=>setPromos(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			<NewPromoModal 
				visible={showNewModal}
				onClose={() => setShowNewModal(false)}
				reload={() => setPromos(null)}
			/>
		</React.Fragment>
	)
}