import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import SuggestionsList from './SuggestionsList'

export default function Suggestions() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/suggestions" component={SuggestionsList} />

                <Redirect to="/404" />
            </Switch>
        </React.Fragment>
    )
}