import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import SuggestionForm from './SuggestionForm'

import { storeSuggestion } from '../services'
import { handleError } from '../../../helpers'

export default function NewSuggestionModal({ visible, onClose, reload }) {
	const { register, handleSubmit, formState: { errors }, reset, setError } = useForm()

	const onSubmit = values => {
        if(parseInt(values.max_days_from_publish) < parseInt(values.min_days_from_publish)){
            setError('max_days_from_publish', true)
            return message.warning('El máximo de días debe ser mayor al mínimo')
        }

		storeSuggestion(values)
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>
                    Crear Mensaje de Seguimiento Sugerido
                </ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<SuggestionForm 
                            register={register} 
                            errors={errors} 
                        />
						<Button type="submit" color="primary">
                            Crear Sugerencia
                        </Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}