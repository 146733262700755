import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import SuggestionForm from './SuggestionForm'

import { updateSuggestion } from '../services'
import { handleError } from '../../../helpers'

export default function EditSuggestionModal({ visible, onClose, reload, suggestion }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		defaultValues: suggestion
	})

	const onSubmit = values => {
		updateSuggestion(suggestion.id, values)
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>
                    Actualizar Mensaje de Seguimiento Sugerido
                </ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<SuggestionForm 
                            register={register} 
                            errors={errors} 
                            suggestion={suggestion} 
                        />
						<Button type="submit" color="primary">
                            Actualizar Sugerencia
                        </Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}